import React, { useState } from 'react';
import { PortfolioBlock, PortfolioWrap, Title, List, ListItem, LogoWrap, ClientTitle, Description } from './portfolio.styled';
import { Container } from '../components/container';
import { Link } from 'react-scroll';
import { Button, ButtonColor, ButtonType } from '../components/button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import notiv from '../assets/img/notiv.webp';
import gatehouse from '../assets/img/gatehouse.webp';
import preshow from '../assets/img/preshow.webp';
import neurable from '../assets/img/neurable.webp';
import moviepass from '../assets/img/moviepass.webp';
import atacama from '../assets/img/atacama.webp';

const Portfolio = () => {
  const [ clients ] = useState([
    { name: 'Notiv (Exited to Dubber in 2021)', iconSrc: notiv, link: 'https://www.choiceworx.com/', background: '#EEFBF6', description: 'Notiv is a platform agnostic (Zoom, Webex, Google, Microsoft, Uber Conference, etc.) AI transcription and notetaking solution' },
    { name: 'Gatehouse Bio', iconSrc: gatehouse, link: 'https://www.choiceworx.com/', background: '#FEF6ED', description: 'Gatehouse Bio is an AI and machine learning biotech company commercializing proprietary therapeutics' },
    { name: 'PrePlay', iconSrc: preshow, link: 'https://www.choiceworx.com/', background: '#F0F9FF', description: 'PreTechnology is an AI mobile-based media and entertainment platform allowing users to monetize their attention through watching advertisements' },
    { name: 'Neurable ', iconSrc: neurable, link: '', background: '#ffffff', description: 'Neurable develops brain-computer interface (BCI) technology. The company uses its AI/ML platform and electroencephalogram (EEG) activity to build an intelligent layer between the brain and computers' },
	{ name: 'moviepass 2.0', iconSrc: moviepass, link: '', background: '#000000', description: 'A web3 and AI company offering monthly subscriptions in exchange for credits that can be redeemed for movie tickets' },
    { name: 'Atacama ', iconSrc: atacama, link: '', background: '#ffffff', description: 'Atacama Biomaterials is a deep tech company leveraging AI and machine learning to produce commercially viable biodegradable and compostable plastic alternatives' },
  ]);

  return (
    <PortfolioBlock id='portfolio'>
      <Container>
        <PortfolioWrap>
          <Title>Portfolio</Title>
          <List>
            {clients.map(({ name, iconSrc, link, background, description }, index) => (
              <ListItem
                key={index}
              >
                <LogoWrap style={{ background }}>
                  <LazyLoadImage src={iconSrc} alt={name} />
                </LogoWrap>
                <ClientTitle>{name}</ClientTitle>
                <Description>{description}</Description>
              </ListItem>
            ))}
          </List>
        </PortfolioWrap>
      </Container>
    </PortfolioBlock>
  );
}

export default Portfolio;
